import {
	buildThing,
	createSolidDataset,
	deleteSolidDataset,
	FetchError,
	getPodUrlAll,
	getSolidDataset,
	getSourceUrl,
	getStringNoLocale,
	getThing,
	getThingAll,
	getUrl,
	isContainer,
	saveSolidDatasetAt,
	setThing
} from "@inrupt/solid-client";
import {fetch, getDefaultSession, handleIncomingRedirect, login} from "@inrupt/solid-client-authn-browser";
import {RDF, SCHEMA_INRUPT} from "@inrupt/vocab-common-rdf";
import Parser from "rss-parser";

const parser = new Parser({
	customFields: {
		item: [
			[`id`, `guid`], // https://github.com/rbren/rss-parser/issues/108
			`media:group`,
			`yt:videoId`, // YouTube
		],
	},
});

// If converting from RSS to Atom, consider the other options too.
//  1. RSS 1.0 & RSS 2.0 to Atom
//  2. Atom & RSS 1.0 to RSS 2.0 <- Probably the least desirable one?
//  3. Atom & RSS 2.0 to RSS 1.0 <- Could be interesting as RSS 1.0 is in RDF. Don't dismiss it just because it's an older version of the spec. RSS 2.0 isn't an evolution of RSS 1.0. It was completely different.
//  4. RSS & Atom to Atom Activity Streams

const backLayerDetails = document.querySelector(`body > aside > details`);
const titleHeader = document.querySelector(`body > aside > section > h4`);
const oidcIssuerInput = document.getElementById(`oidcIssuerInput`);
const loginButton = document.getElementById(`btnLogin`);
const profileButton = document.getElementById(`profile-button`);
const profileDialog = document.getElementById(`profile-dialog`);
const selectorPod = document.querySelector(`#select-pod`);
const addFolderInput = document.getElementById(`addFolderInput`);
const addFolderButton = document.getElementById(`addFolderButton`);
const addFeedInput = document.getElementById(`addFeedInput`);
const addFeedButton = document.getElementById(`addFeedButton`);
const importFeedsButton = document.getElementById(`importFeedsButton`);
const feedsList = document.querySelector(`body > aside > details > nav > ul`);

// TODO Continue reading https://docs.inrupt.com/developer-tools/javascript/client-libraries/tutorial/getting-started-explanation/
// TODO Similarly, rename and delete stuff so it's non-obvious this was ripped from a tutorial(s).
// TODO git stash pop all the things until it's empty. Got some good stuff.

window.matchMedia(`screen and (width >= 70rem)`).addEventListener(`change`, event => {
	if (event.matches) {
		backLayerDetails.open = true;
		titleHeader.textContent = `Recess`;
	}
});

window.matchMedia(`screen and (width >= 50rem)`).addEventListener(`change`, event => {
	if (event.matches) {
		document.querySelector(`body > main > ol`).style.removeProperty(`display`);
		document.querySelector(`body > main > div`).style.removeProperty(`display`);
	}
});

async function handleRedirectAfterLogin() {
	// TODO If I need to restore browser state on refresh (might just be related to frameworks) follow
	//  https://docs.inrupt.com/developer-tools/javascript/client-libraries/tutorial/restore-session-browser-refresh/#use-session-restore-event-handler
	await handleIncomingRedirect({restorePreviousSession: true});

	const session = getDefaultSession();
	if (session.info.isLoggedIn) {
		oidcIssuerInput.hidden = true;
		loginButton.hidden = true;
		profileButton.hidden = false;
		getPods();
	} else {
		// TODO Figure out what a Client ID Document is and use it
		//  https://docs.inrupt.com/developer-tools/javascript/client-libraries/tutorial/authenticate-client/
		loginButton.addEventListener(`click`, () => {
			login({
				oidcIssuer: oidcIssuerInput.value,
				redirectUrl: window.location.href,
				clientName: `Recess`,
			});
		});
	}
}

handleRedirectAfterLogin();

async function getPods() {
	const webID = getDefaultSession().info.webId;
	// TODO getPodUrlAll is empty using CSS.
	//  I think it's because I don't have pim:storage set anywhere?
	//  As I use CSS for development, I've hardcoded localhost:3000 in the interim.
	//  https://github.com/CommunitySolidServer/CommunitySolidServer/issues/910
	const podUrls = [`http://localhost:3000/`, ...await getPodUrlAll(webID, { fetch: fetch })];

	podUrls.forEach((podUrl) => {
		let podOption = document.createElement(`option`);
		podOption.textContent = podUrl;
		podOption.value = podUrl;
		selectorPod.appendChild(podOption);
	});

	const previousSelectedPod = localStorage.getItem(`pod`);
	if (previousSelectedPod !== null) {
		selectorPod.value = previousSelectedPod;
		selectorPod.dispatchEvent(new Event(`change`));
	}
}

function getFeedsUrl() {
	// TODO For the sake of simplicity and brevity, this getting started guide hardcodes the SolidDataset URL.
	// 	In practice, you should add a link to this resource in your profile
	// 	that applications can follow.
	return `${(selectorPod.value)}veyndan/feeds/`;
}

async function getOrCreateSolidDataset(url, options) {
	let dataset;

	try {
		dataset = await getSolidDataset(url, options);
		console.log(`Existing dataset retrieved at ${url}.`);
	} catch (error) {
		if (error instanceof FetchError && error.statusCode === 404) {
			console.log(`Existing dataset not found. Creating a new one at ${url}.`);
			dataset = createSolidDataset();
		} else {
			throw error;
		}
	}

	return dataset;
}

async function addFolder(name) {
	const feedsUrl = getFeedsUrl();

	let feedDataset = await createSolidDataset();
	let feedDatasetThing = buildThing({name: `it`})
		.addStringNoLocale(SCHEMA_INRUPT.name, name)
		.build();
	feedDataset = setThing(feedDataset, feedDatasetThing);

	return saveSolidDatasetAt(
		`${feedsUrl}${encodeURIComponent(name)}/self`,
		feedDataset,
		{fetch: fetch},
	);
}

async function populateActivityPubFeed(url) {
	const response = await fetch(`https://recess.pages.dev/proxy?url=${encodeURIComponent(url)}`, {headers: {'ngrok-skip-browser-warning': 'skip-browser-warning'}});
	const json = await response.json();
	console.log(json);
	/** @type {FeedElement} */
	const feedElement = document.createElement(`recess-feed`);
	// feedElement.logo = new URL(`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${feed.link}&size=64`);
	feedElement.name = "Veyndan Stuart";
	const feedLi = document.createElement(`li`);
	feedLi.setAttribute(`role`, `treeitem`);
	feedLi.draggable = true;
	feedLi.setAttribute(`aria-selected`, `false`);
	feedLi.addEventListener(`click`, () => {
		feedsList.querySelectorAll(`li`).forEach(element => element.setAttribute(`aria-selected`, `false`));
		feedLi.setAttribute(`aria-selected`, `true`);
		if (!window.matchMedia(`screen and (width >= 70rem)`).matches) {
			backLayerDetails.open = false;
			titleHeader.textContent = "Veyndan Stuart";
		}
		populateActivityPubSummaries(json);
	});
	let unreadCount = 0;
	const feedButton = document.createElement(`button`);
	feedButton.type = `button`;
	feedButton.appendChild(feedElement);
	feedLi.appendChild(feedButton);
	feedsList.appendChild(feedLi);
	feedElement.unreadCount = unreadCount;
}

function populateActivityPubSummaries(json) {
	const summaries = document.querySelector(`body > main > ol`);
	summaries.scrollTop = 0;
	summaries.querySelectorAll(`li`).forEach(element => element.remove());
	for (const item of json["orderedItems"]) {
		/** @type {SummaryElement} */
		const summaryArticle = document.createElement(`article`, {is: `recess-summary`});
		if (undefined !== undefined) {
			summaryArticle.titleName = undefined;
		}
		if (item[`media:group`] !== undefined) {
			const thumbnail = item[`media:group`][`media:thumbnail`][0][`$`];
			const content = item[`media:group`][`media:content`][0][`$`];
			const thumbnailElement = document.createElement(`recess-thumbnail`);
			thumbnailElement.src(thumbnail.url, content.width, content.height);
			thumbnailElement.viewCount = item[`media:group`][`media:community`][0][`media:statistics`][0][`$`][`views`];
			summaryArticle.slot = thumbnailElement;
		}
		if (item.object.content !== undefined) {
			summaryArticle.description = new window.DOMParser()
				.parseFromString(item.object.content, `text/html`)
				.querySelector(`:first-child`)
				.textContent;
		} else if (item[`media:group`] !== undefined && item[`media:group`][`media:description`][0] !== null) {
			summaryArticle.description = item[`media:group`][`media:description`][0].split(`\n`, 1)[0];
		}
		if (item.creator !== undefined) {
			summaryArticle.creator = item.creator;
		}
		summaryArticle.publishedAt = new Date(Date.parse(item.pubDate));
		const summaryButton = document.createElement(`button`);
		summaryButton.type = `button`;
		summaryButton.appendChild(summaryArticle);
		summaryButton.addEventListener(`click`, async (event) => {
			if (event.target !== summaryArticle.isReadElement) {
				summaryArticle.isRead = true;
				summaries.querySelectorAll(`li > button`).forEach(element => element.classList.remove(`selected`));
				summaryButton.classList.add(`selected`);
				populateActivityPubMainArticle(item);
			}
		});
		const summaryLi = document.createElement(`li`);
		summaryLi.appendChild(summaryButton);
		summaries.appendChild(summaryLi);
	}
}

function populateActivityPubMainArticle(item) {
	if (!window.matchMedia(`screen and (width >= 50rem)`).matches) {
		document.querySelector(`body > main > ol`).style.display = `none`;
		document.querySelector(`body > main > div`).style.display = `initial`;
		document.querySelector(`body > main > div > menu[role="toolbar"] > li > button`).addEventListener(`click`, () => {
			document.querySelector(`body > main > ol`).style.display = `initial`;
			document.querySelector(`body > main > div`).style.display = `none`;
		});
	}
	document.querySelector(`body > main > div`).scrollTop = 0;
	document.querySelector(`body > main > div > menu[role="toolbar"] > li > a`).href = item.link;
	const empty = document.querySelector(`body > main > div > span`);
	if (empty !== null) {
		empty.remove();
	}
	/** @type {ArticleElement} */
	const article = document.querySelector(`body > main > div > article`);
	if (undefined !== undefined) {
		article.titleName = undefined;
	}
	if (item[`yt:videoId`] !== undefined) {
		const content = item[`media:group`][`media:content`][0][`$`];
		article.media(`https://www.youtube.com/embed/${item[`yt:videoId`]}`, content.width, content.height);
	} else {
		article.removeMedia();
	}
	if (item[`content:encoded`] !== undefined) {
		article.content(item[`content:encoded`], `html`);
	} else if (item.object.content !== undefined) {
		article.content(item.object.content, `html`);
	} else if (item.summary !== undefined) {
		// TODO Change type if summary type is "text" or absent
		article.content(item.summary, `html`);
	} else if (item[`media:group`][`media:description`][0] !== null) {
		article.content(item[`media:group`][`media:description`][0], `plain`);
	}
}

async function addFeed(url, folderName) {
	if (url === `https://6017-88-78-150-193.ngrok-free.app/foo.json`) {
		console.log(`nice`);
		populateActivityPubFeed(url)
		return;
	}

	const feedsUrl = getFeedsUrl();

	let feedsDataset = await getOrCreateSolidDataset(feedsUrl, {fetch: fetch});
	const datasetContainsFeedUrl = getThingAll(feedsDataset)
		.map(thing => getUrl(thing, SCHEMA_INRUPT.url))
		.some(feedUrl => feedUrl === url);

	if (datasetContainsFeedUrl) {
		console.log(`Dataset already contains ${url}`);
		return;
	}

	let feedDataset = await createSolidDataset();
	const dataFeed = buildThing({name: `DataFeed`})
		// TODO ActivityStreams?
		.addUrl(RDF.type, SCHEMA_INRUPT.NS(`DataFeed`))
		.addUrl(SCHEMA_INRUPT.url, url)
		.build();
	feedDataset = setThing(feedDataset, dataFeed);

	return saveSolidDatasetAt(
		folderName !== undefined ? `${feedsUrl}${encodeURIComponent(folderName)}/${encodeURIComponent(url)}` : `${feedsUrl}${encodeURIComponent(url)}`,
		feedDataset,
		{fetch: fetch},
	);
}

async function populateFeeds(feedsDatasetPromise = getOrCreateSolidDataset(getFeedsUrl(), {fetch: fetch})) {
	feedsList.querySelectorAll(`li`).forEach(element => element.remove());
	feedsList.addEventListener(`dragover`, event => {
		// TODO Display indication that you can drop it here (like trigger the hover state)
		//  Even better would be to show some sort of "Add here" text. Make it crystal clear what's going on.
		//  Could have the "ring" effect that you have one focusing something.
		event.preventDefault();
	});
	feedsList.addEventListener(`drop`, async event => {
		// TODO Currently a feed can only be de-foldered if there is at least one item that is already de-foldered.
		//  This is because there is no drop zone to de-folder an item. This should be fixed.
		event.preventDefault();
		const feedDatasetUrl = event.dataTransfer.getData(`text/plain`);
		await populateFeed(await getSolidDataset(feedDatasetUrl, {fetch: fetch}), feedsList);
	});
	const feedDataset = await feedsDatasetPromise;
	const feedDatasetUrl = getSourceUrl(feedDataset);
	const feedDatasets = await Promise.all(
		getThingAll(feedDataset)
			.filter(thing => thing.url !== feedDatasetUrl)
			.map(async thing => await getSolidDataset(thing.url, {fetch: fetch})),
	);
	await Promise.all(feedDatasets.map(feedDataset => populateFeed(feedDataset, feedsList)));
}

async function populateFeed(feedDataset, feedContainer, unreadCountCallback = undefined, feedDatasetUrl = getSourceUrl(feedDataset)) {
	if (isContainer(feedDataset)) {
		const feedDatasetThing = getThing(await getSolidDataset(`${feedDatasetUrl}self`, {fetch: fetch}), `${feedDatasetUrl}self#it`);
		const nestedDatasets = await Promise.all(
			getThingAll(feedDataset)
				.filter(thing => thing.url !== feedDatasetUrl && thing.url !== `${feedDatasetUrl}self`)
				.map(async thing => await getSolidDataset(thing.url, {fetch: fetch})),
		);
		const nestedFeedDatasets = nestedDatasets
			.filter(dataset => getThing(dataset, `${getSourceUrl(dataset)}#DataFeed`) !== null);
		const folderLi = document.createElement(`li`);
		folderLi.setAttribute(`role`, `treeitem`);
		const folderDetails = document.createElement(`details`);
		const folderSummary = document.createElement(`summary`);
		const folderContent = document.createElement(`ul`);
		folderContent.setAttribute(`role`, `group`);
		/** @type {FolderElement} */
		const folderElement = document.createElement(`recess-folder`);
		folderElement.name = getStringNoLocale(feedDatasetThing, SCHEMA_INRUPT.name);
		folderDetails.addEventListener(`toggle`, () => {
			/** @type {FolderElement} */
			const folderElement = folderDetails.querySelector(`summary > recess-folder`);
			folderElement.isOpen = folderDetails.open;
		});
		folderLi.addEventListener(`dragover`, event => {
			event.preventDefault();
			event.stopPropagation();
		});
		folderLi.addEventListener(`dragenter`, () => {
			folderLi.classList.add(`dropzone`);
		});
		folderLi.addEventListener(`dragleave`, event => {
			if (!folderLi.contains(event.relatedTarget)) {
				folderLi.classList.remove(`dropzone`);
			}
		});

		let feedDatasetUrlToUnreadCount = new Map();

		const populateUnreadCount = (_feedDatasetUrl, _unreadCount) => {
			feedDatasetUrlToUnreadCount.set(_feedDatasetUrl, _unreadCount);
			folderElement.unreadCount = Array.from(feedDatasetUrlToUnreadCount.values())
				.reduce((accumulator, current) => accumulator + current);
		};

		folderLi.addEventListener(`drop`, async event => {
			event.preventDefault();
			event.stopPropagation();
			folderLi.classList.remove(`dropzone`);
			const sourceFeedDatasetUrl = event.dataTransfer.getData(`text/plain`);
			const sourceFeedDatasetUrlPaths = sourceFeedDatasetUrl.split(`/`);
			const sourceFeedDatasetUrlPathResource = sourceFeedDatasetUrlPaths[sourceFeedDatasetUrlPaths.length - 1];
			const sourceFeedDataset = await getSolidDataset(sourceFeedDatasetUrl, {fetch: fetch});
			const destinationFeedDataset = await saveSolidDatasetAt(`${feedDatasetUrl}${sourceFeedDatasetUrlPathResource}`, sourceFeedDataset, {fetch: fetch});
			await deleteSolidDataset(sourceFeedDatasetUrl, {fetch: fetch});
			await populateFeed(destinationFeedDataset, folderContent, populateUnreadCount, sourceFeedDatasetUrl);
		});

		folderSummary.appendChild(folderElement);
		folderDetails.appendChild(folderSummary);
		folderDetails.appendChild(folderContent);
		folderLi.appendChild(folderDetails);
		feedContainer.appendChild(folderLi);

		await Promise.all(nestedFeedDatasets.map(feedDataset => populateFeed(feedDataset, folderContent, populateUnreadCount)));
	} else {
		const dataFeed = getThing(feedDataset, `${getSourceUrl(feedDataset)}#DataFeed`);
		const feedUrl = getUrl(dataFeed, SCHEMA_INRUPT.url);
		// TODO Should this be cached? It'd be annoying if on each page reload we request all the articles for the image and name.
		//  Should this be done in the browser cache, or should we just write it to Solid?
		//  Does Solid special case caching somewhere, or have advice on it?
		await parser.parseURL(`https://recess.pages.dev/proxy?url=${encodeURIComponent(feedUrl)}`)
			.then(feed => {
				/** @type {FeedElement} */
				const feedElement = document.createElement(`recess-feed`);
				feedElement.logo = new URL(`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${feed.link}&size=64`);
				feedElement.name = feed.title;
				const feedLi = document.createElement(`li`);
				feedLi.setAttribute(`role`, `treeitem`);
				feedLi.draggable = true;
				feedLi.setAttribute(`aria-selected`, `false`);
				feedLi.addEventListener(`click`, () => {
					feedsList.querySelectorAll(`li`).forEach(element => element.setAttribute(`aria-selected`, `false`));
					feedLi.setAttribute(`aria-selected`, `true`);
					if (!window.matchMedia(`screen and (width >= 70rem)`).matches) {
						backLayerDetails.open = false;
						titleHeader.textContent = feed.title;
					}
					populateSummaries(feedDataset, feed.items);
					document.querySelectorAll(`body > main > ol > li`).forEach((summary, index) => {
						const item = feed.items[index];
						summary.addEventListener(`click`, async () => {
							const isRead = summary.querySelector(`li > button > article > header > input[type=checkbox]`);
							const dataFeedItem = getThing(feedDataset, `${feedDatasetUrl}#DataFeedItem_${(item.guid)}`);
							const potentialAction = getThing(feedDataset, getUrl(dataFeedItem, SCHEMA_INRUPT.NS(`potentialAction`)));
							const updatedReadAction = buildThing(potentialAction)
								.setUrl(SCHEMA_INRUPT.NS(`actionStatus`), SCHEMA_INRUPT.NS(isRead.checked ? `CompletedActionStatus` : `PotentialActionStatus`))
								.build();
							feedDataset = setThing(feedDataset, updatedReadAction);
							let unreadCount = 0;
							feed.items.forEach(item => {
								const dataFeedItem = getThing(feedDataset, `${feedDatasetUrl}#DataFeedItem_${(item.guid)}`);
								const potentialAction = getThing(feedDataset, getUrl(dataFeedItem, SCHEMA_INRUPT.NS(`potentialAction`)));
								const actionStatus = getUrl(potentialAction, SCHEMA_INRUPT.NS(`actionStatus`));
								if (actionStatus === SCHEMA_INRUPT.NS(`PotentialActionStatus`)) {
									unreadCount++;
								}
							});
							if (unreadCountCallback !== undefined) {
								unreadCountCallback(feedDatasetUrl, unreadCount);
							}
							feedElement.unreadCount = unreadCount;
							feedDataset = await saveSolidDatasetAt(feedDatasetUrl, feedDataset, {fetch: fetch});
						});
					});
				});
				feedLi.addEventListener(`dragstart`, event => {
					event.dataTransfer.dropEffect = `move`;
					event.dataTransfer.setData(`text/plain`, feedDatasetUrl);
				});
				let unreadCount = 0;
				feedLi.addEventListener(`dragend`, (event) => {
					if (event.dataTransfer.dropEffect === `move`) {
						if (unreadCountCallback !== undefined) {
							unreadCountCallback(feedDatasetUrl, 0);
						}
						feedContainer.removeChild(feedLi);
					}
				});
				const feedButton = document.createElement(`button`);
				feedButton.type = `button`;
				feedButton.appendChild(feedElement);
				feedLi.appendChild(feedButton);
				feedContainer.appendChild(feedLi);
				const updatedDataFeedBuilder = buildThing(dataFeed);
				feed.items.forEach(item => {
					let actionStatus;
					let dataFeedItem = getThing(feedDataset, `${feedDatasetUrl}#DataFeedItem_${(item.guid)}`);
					if (dataFeedItem === null) {
						actionStatus = SCHEMA_INRUPT.NS(`PotentialActionStatus`);
						const potentialAction = buildThing({name: `ReadAction_${(item.guid)}`})
							.addUrl(RDF.type, SCHEMA_INRUPT.NS(`ReadAction`))
							.addUrl(SCHEMA_INRUPT.NS(`actionStatus`), actionStatus)
							.build();
						dataFeedItem = buildThing({name: `DataFeedItem_${(item.guid)}`})
							.addUrl(RDF.type, SCHEMA_INRUPT.NS(`DataFeedItem`))
							.addStringNoLocale(SCHEMA_INRUPT.identifier, item.guid)
							.addUrl(SCHEMA_INRUPT.NS(`potentialAction`), potentialAction)
							.build();
						updatedDataFeedBuilder
							.addUrl(SCHEMA_INRUPT.NS(`dataFeedElement`), dataFeedItem);
						feedDataset = setThing(feedDataset, potentialAction);
						feedDataset = setThing(feedDataset, dataFeedItem);
					} else {
						const potentialAction = getThing(feedDataset, getUrl(dataFeedItem, SCHEMA_INRUPT.NS(`potentialAction`)));
						actionStatus = getUrl(potentialAction, SCHEMA_INRUPT.NS(`actionStatus`));
					}
					if (actionStatus === SCHEMA_INRUPT.NS(`PotentialActionStatus`)) {
						unreadCount++;
					}
				});
				feedDataset = setThing(feedDataset, updatedDataFeedBuilder.build());
				if (unreadCountCallback !== undefined) {
					unreadCountCallback(feedDatasetUrl, unreadCount);
				}
				feedElement.unreadCount = unreadCount;
			});
		feedDataset = await saveSolidDatasetAt(getSourceUrl(feedDataset), feedDataset, {fetch: fetch});
	}
}

function populateSummaries(feedDataset, items) {
	const summaries = document.querySelector(`body > main > ol`);
	summaries.scrollTop = 0;
	summaries.querySelectorAll(`li`).forEach(element => element.remove());
	for (const item of items) {
		/** @type {SummaryElement} */
		const summaryArticle = document.createElement(`article`, {is: `recess-summary`});
		if (item.title !== undefined) {
			summaryArticle.titleName = item.title;
		}
		if (item[`media:group`] !== undefined) {
			const thumbnail = item[`media:group`][`media:thumbnail`][0][`$`];
			const content = item[`media:group`][`media:content`][0][`$`];
			const thumbnailElement = document.createElement(`recess-thumbnail`);
			thumbnailElement.src(thumbnail.url, content.width, content.height);
			thumbnailElement.viewCount = item[`media:group`][`media:community`][0][`media:statistics`][0][`$`][`views`];
			summaryArticle.slot = thumbnailElement;
		}
		if (item.content !== undefined) {
			summaryArticle.description = new window.DOMParser()
				.parseFromString(item.content, `text/html`)
				.querySelector(`:first-child`)
				.textContent;
		} else if (item[`media:group`] !== undefined && item[`media:group`][`media:description`][0] !== null) {
			summaryArticle.description = item[`media:group`][`media:description`][0].split(`\n`, 1)[0];
		}
		if (item.creator !== undefined) {
			summaryArticle.creator = item.creator;
		}
		summaryArticle.publishedAt = new Date(Date.parse(item.pubDate));
		const dataFeedItem = getThing(feedDataset, `${getSourceUrl(feedDataset)}#DataFeedItem_${(item.guid)}`);
		const potentialAction = getThing(feedDataset, getUrl(dataFeedItem, SCHEMA_INRUPT.NS(`potentialAction`)));
		const actionStatus = getUrl(potentialAction, SCHEMA_INRUPT.NS(`actionStatus`));
		summaryArticle.isRead = actionStatus === SCHEMA_INRUPT.NS(`CompletedActionStatus`);
		const summaryButton = document.createElement(`button`);
		summaryButton.type = `button`;
		summaryButton.appendChild(summaryArticle);
		summaryButton.addEventListener(`click`, async (event) => {
			if (event.target !== summaryArticle.isReadElement) {
				summaryArticle.isRead = true;
				summaries.querySelectorAll(`li > button`).forEach(element => element.classList.remove(`selected`));
				summaryButton.classList.add(`selected`);
				populateMainArticle(item);
			}
		});
		const summaryLi = document.createElement(`li`);
		summaryLi.appendChild(summaryButton);
		summaries.appendChild(summaryLi);
	}
}

function populateMainArticle(item) {
	if (!window.matchMedia(`screen and (width >= 50rem)`).matches) {
		document.querySelector(`body > main > ol`).style.display = `none`;
		document.querySelector(`body > main > div`).style.display = `initial`;
		document.querySelector(`body > main > div > menu[role="toolbar"] > li > button`).addEventListener(`click`, () => {
			document.querySelector(`body > main > ol`).style.display = `initial`;
			document.querySelector(`body > main > div`).style.display = `none`;
		});
	}
	document.querySelector(`body > main > div`).scrollTop = 0;
	document.querySelector(`body > main > div > menu[role="toolbar"] > li > a`).href = item.link;
	const empty = document.querySelector(`body > main > div > span`);
	if (empty !== null) {
		empty.remove();
	}
	/** @type {ArticleElement} */
	const article = document.querySelector(`body > main > div > article`);
	if (item.title !== undefined) {
		article.titleName = item.title;
	}
	if (item[`yt:videoId`] !== undefined) {
		const content = item[`media:group`][`media:content`][0][`$`];
		article.media(`https://www.youtube.com/embed/${item[`yt:videoId`]}`, content.width, content.height);
	} else {
		article.removeMedia();
	}
	if (item[`content:encoded`] !== undefined) {
		article.content(item[`content:encoded`], `html`);
	} else if (item.content !== undefined) {
		article.content(item.content, `html`);
	} else if (item.summary !== undefined) {
		// TODO Change type if summary type is "text" or absent
		article.content(item.summary, `html`);
	} else if (item[`media:group`][`media:description`][0] !== null) {
		article.content(item[`media:group`][`media:description`][0], `plain`);
	}
}

addFolderButton.addEventListener(`click`, async () => {
	await addFolder(addFolderInput.value);
	await populateFeeds();
});
addFeedButton.addEventListener(`click`, async () => {
	await addFeed(addFeedInput.value);
	// await populateFeeds();
});
importFeedsButton.addEventListener(`change`, async () => {
	const file = importFeedsButton.files[0];
	const fileDocument = new window.DOMParser().parseFromString(await file.text(), `text/xml`);
	for await (const folder of fileDocument.querySelectorAll(`body > outline`)) {
		const folderName = folder.getAttribute(`text`);
		await addFolder(folderName);
		for await (const feed of folder.querySelectorAll(`outline > outline`)) {
			await addFeed(feed.getAttribute(`xmlUrl`), folderName);
		}
	}
	await populateFeeds();
});

selectorPod.addEventListener(`change`, () => localStorage.setItem(`pod`, selectorPod.value));
selectorPod.addEventListener(`change`, () => populateFeeds());
selectorPod.addEventListener(`change`, toggleAddFolderButtonDisabledAttribute);
selectorPod.addEventListener(`change`, toggleAddFeedButtonDisabledAttribute);
addFolderInput.addEventListener(`input`, toggleAddFolderButtonDisabledAttribute);
addFeedInput.addEventListener(`input`, toggleAddFeedButtonDisabledAttribute);

function toggleAddFolderButtonDisabledAttribute() {
	if (addFolderInput.value.trim() !== `` && selectorPod.value !== ``) {
		addFolderButton.removeAttribute(`disabled`);
	} else {
		addFolderButton.setAttribute(`disabled`, `disabled`);
	}
}

function toggleAddFeedButtonDisabledAttribute() {
	if (addFeedInput.value.trim() !== `` && selectorPod.value !== ``) {
		addFeedButton.removeAttribute(`disabled`);
	} else {
		addFeedButton.setAttribute(`disabled`, `disabled`);
	}
}

profileButton.addEventListener(`click`, (event) => {
	event.stopImmediatePropagation();
	const abortController = new AbortController();
	const dismissListener = (event) => {
		// TODO When <select> is open, pressing the escape key should close the select, not the dialog.
		// TODO When the dialog is open, toggling the read status should close the dialog.
		if (event.key === `Escape` || !profileDialog.contains(event.target)) {
			abortController.abort();
			profileDialog.close();
		}
	};
	document.addEventListener(`click`, dismissListener, {signal: abortController.signal});
	document.addEventListener(`keyup`, dismissListener, {signal: abortController.signal});
	// TODO Do profileDialog.showModal(); on mobile.
	profileDialog.show();
});

document.querySelector(`body > aside > details`).addEventListener("scroll", (event) => {
	if (event.currentTarget.scrollTop === 0) {
		document.querySelector(`body > aside > section`).classList.remove(`elevate`);
	} else {
		document.querySelector(`body > aside > section`).classList.add(`elevate`);
	}
});
